@import url(//db.onlinewebfonts.com/c/9e00143409affcb46a1ae58634aa64be?family=Sofia+Pro);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("//db.onlinewebfonts.com/t/9e00143409affcb46a1ae58634aa64be.eot");
  src: url("//db.onlinewebfonts.com/t/9e00143409affcb46a1ae58634aa64be.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/9e00143409affcb46a1ae58634aa64be.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/9e00143409affcb46a1ae58634aa64be.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/9e00143409affcb46a1ae58634aa64be.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/9e00143409affcb46a1ae58634aa64be.svg#Sofia Pro")
      format("svg");
}

/* Dark Mode Colors */

[data-theme="dark"] {
  /* --bg-gradient: radial-gradient(
    90deg,
    rgba(20, 30, 48, 1) 15.2%,
    rgba(36, 59, 85, 1) 91.1%
  ); */

  /* --bg-gradient: radial-gradient(#141e30, #243b55); */
  /* --bg-gradient: radial-gradient(#0a0e17, #141e30); */
  --bg-gradient: radial-gradient(#141e30, #0a0e17);
  --bg-color: #101b2d;
  /* --bg-color: radial-gradient(#141e30, #0a0e17); */
  --dropdown-bg: rgba(20, 30, 48, 0.75);
  /* --bg-color: #11192c; */
  /* --logo-main: url("../interchange.png"); */
  --text-color: #fff;
  --dark-navy: #11192c;
  --navbar-color: rgba(255, 255, 255, 0.05);
  --dd-background: #2f3b4d;
  --navbar-color: rgba(255, 255, 255, 0.05);
  --main: white !important;
  --main-opposite: #000f50;
  /* --secondary: ; */
  --main-container: rgba(255, 255, 255, 0.05);
  --main-hover: rgba(255, 255, 255, 0.05);
  --main-transparent: rgba(255, 255, 255, 0.05);
  --modal-background: #2f3b4d;
  --modal-content: rgba(255, 255, 255, 0.05);
  --transparent-container: rgba(255, 255, 255, 0.05);
  --mobile-button: rgba(255, 255, 255, 0.05);
  --transparent-container-focus: rgba(255, 255, 255, 0.15);
  --selected-transparent: rgba(255, 255, 255, 0.1);
  --navy-main: white;
  --navy-hover: rgba(5, 11, 92, 0.65);
  --navy-transparent: rgba(5, 11, 92, 0.05);
  --blue-secondary: #92a0f8;
  --ol-highlight: #00cee6;
  --emb-color: #f6c054;
  --ol-color: rgb(0, 169, 188);
  --btc-color: #f7931a;
  --eth-color: #7582c5;
  --dai-color: #f2aa1d;
  --lsk-color: #0b4d95;
  --wav-color: #0093d3;
  --usdt-color: #269c78;
  --neo-color: #55b900;
  --yellow-color: #fec901;
  --yellow-btn: rgba(254, 201, 1, 0.1);
  --ol-btn: rgba(0, 169, 188, 0.1);
  --green-color: #00a9bc;
  --gray-color: rgba(160, 160, 162, 1);
  --gray-light: rgba(160, 160, 162, 0.25);
  --large-text: 4rem;
  --small-text: 0.9rem;
  --medium-text: 2rem;
  --standard-text: 1rem;
  --subheading-text: 1.25rem;
  --selected-text: 1.5rem;
  --regular: 400;
  --bold: 600;
  --semilight: 300;
  --light: 200;
  --ol-transparent: rgba(31, 169, 188, 0.5);
  --box-shadow: rgb(0 0 0 / 0.01) 0px 0px 1px, rgb(0 0 0 / 0.04) 0px 4px 8px,
    rgb(0 0 0 / 0.04) 0px 16px 24px, rgb(0 0 0 / 0.01) 0px 24px 32px;
  --bs-info: var(--ol-color) !important;
}

/*  Light Mode Colors */

:root {
  --bg-color: white;
  /* --bg-gradient: radial-gradient(#fdf3e0, #e5f7ed); */
  --bg-gradient: #f1f0fa;
  --dropdown-bg: rgba(255, 255, 255, 0.75);
  /* --logo-main: url("../interchange.png"); */
  --text-color: #000;
  --dark-navy: #11192c;
  --dd-background: #f3f3f3;
  /* --navbar-color: white; */
  --navbar-color: #000f50;
  /* --main: black; */
  --main: black;
  --main-opposite: white;
  --modal-background: white;
  --modal-content: rgba(0, 0, 0, 0.05);
  --main-container: rgba(255, 255, 255, 1);
  --main-transparent: #f2f2f2;
  --main-hover: rgba(0, 0, 0, 0.05);
  --navy-main: white;
  --navy-hover: rgba(5, 11, 92, 0.65);
  --navy-transparent: rgba(5, 11, 92, 0.05);
  --transparent-container: rgba(32, 169, 188, 0.1);
  --mobile-button: black;
  --transparent-container-focus: rgba(5, 11, 92, 0.15);
  --selected-transparent: #f2f2f2;
  --blue-secondary: #92a0f8;
  --ol-highlight: #00cee6;
  --emb-color: #f6c054;
  --ol-color: #00a9bc;
  --btc-color: #f7931a;
  --eth-color: #7582c5;
  --dai-color: #f2aa1d;
  --lsk-color: #0b4d95;
  --wav-color: #0093d3;
  --usdt-color: #269c78;
  --neo-color: #55b900;
  --green-color: #4fbf67;
  --yellow-color: #fec901;
  --yellow-btn: rgba(254, 201, 1, 0.1);
  --ol-btn: rgba(0, 169, 188, 0.1);
  --gray-color: rgba(160, 160, 162, 1);
  --gray-light: rgba(160, 160, 162, 0.25);
  --large-text: 4rem;
  --medium-text: 2rem;
  --standard-text: 1rem;
  --small-text: 0.9rem;
  --subheading-text: 1.25rem;
  --selected-text: 1.5rem;
  --regular: 400;
  --bold: 600;
  --light: 200;
  --ol-transparent: rgba(31, 169, 188, 0.5);
  --box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
  --bs-info: var(--ol-color) !important;
  --heading-text-desktop: 72px;
  --heading-text-tablet: 52px;
  --heading-text-mobile: 32px;
  --description-text-desktop: 20px;
  --description-text-tablet: 16px;
  --description-text-mobile: 12px;
  --calc-heading-mobile: 28px;
}

/* Global App Classes */

body {
  font-family: "Sofia Pro";
  background: white !important;
  background: var(--bg-color) !important;
  background-color: white !important;
  background-color: var(--bg-color) !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

from .App {
  height: 100%;
}

to .App {
  height: auto;
}

.App {
  background: white;
  background: var(--bg-color);
  height: 100%;
  overflow: hidden;
  /* background-image: url(./assets/stars.svg); */
}

.hidden {
  display: none !important;
}

.mobile-toggle {
  background: none;
  border: none;
  color: white;
  z-index: 2;
  margin-bottom: 1px;
}

/* Glass */

.mobile-menu {
  position: relative;
  display: grid;
  justify-content: center;
  width: 44px;
  height: 44px;
  right: 0px;
  border-radius: 50%;
  background: rgba(15, 14, 71, 0.3);
  box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
  /* backdrop-filter: blur(40px); */
  cursor: pointer;
}

.stars {
  position: absolute;
  width: 100%;
  background-position: center top;
  background-repeat: repeat;
  background-image: url(/static/media/stars.468645e5.svg);
  height: 224px;
  top: 10px;
  display: block;
}

.interface-wrapper {
  position: relative;
}

.google-play {
  cursor: not-allowed !important;
}

@media (max-width: 768px) {
  .platform-button {
    cursor: not-allowed !important;
  }
  .interface-wrapper {
    top: 0px !important;
    left: -75px !important;
    transform: scale(0.4) !important;
  }
  .mobile-hidden {
    display: none !important;
  }

  .desktop-only {
    display: none !important;
  }

  .exchange-modal {
    position: absolute;
  }

  .media-list {
    padding: 20px 0px;
    display: block !important;
  }

  .media-col {
    margin: 15px 0px;
  }

  .product-section {
    padding: 50px 0px 0px 0px !important;
    text-align: center !important;
    list-style-type: none !important;
  }

  .mobile-buttons {
    padding-left: 2rem;
  }

  .product-section ul {
    list-style-type: none !important;
  }
}

.newsletter-input {
  border-radius: 10px !important;
  margin-bottom: 20px;
}

.newsletter-submit {
  border-radius: 10px !important;
}

@media (max-width: 768px) {
  .interface-wrapper {
    transform: scale(0.8);
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .card1 {
    box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
  }
}
.card1 {
  background: radial-gradient(
    218.51% 281.09% at 100% 100%,
    rgba(25, 125, 177, 0.6) 0%,
    rgba(20, 54, 100, 0.6) 45.83%,
    rgba(25, 90, 218, 0.6) 100%
  );
  width: 183px;
  height: 120px;
  position: absolute;
  top: 150px;
  left: -37px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 25%) 0px 20px 40px,
    rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
}
@media (min-width: 450px) {
  /* .interface-wrapper > * {
    transform: rotateY(-20deg) rotateX(20deg);
    transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  } */
}

.card1 img {
  width: 183px;
  height: 120px;
}

/* .interface-wrapper img {
  opacity: 1;
  animation: 1s ease 0s 1 normal forwards running;
  padding: 10px;
} */

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .card2 {
    box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
  }
}

.card2 {
  background: linear-gradient(
    192.64deg,
    rgba(25, 125, 177, 0.6) 0%,
    rgba(20, 54, 100, 0.6) 45.83%,
    rgba(25, 90, 218, 0.6) 100%
  );
  width: 183px;
  height: 120px;
  position: absolute;
  top: 150px;
  left: 176px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 25%) 0px 20px 40px,
    rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
}

/* Main Glass */

@media (min-width: 450px) {
  /* .interface-wrapper:hover > .main-glass {
    transform: translate(0px, 0px);
  } */
}

@media (min-width: 450px) {
  /* .interface-wrapper:hover > * {
    transform: rotateY(0deg) rotateX(0deg);
  } */
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .main-glass {
    box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
  }
}

.main-glass img {
  width: 701px;
  height: 428px;
  max-width: 100%;
  padding: 0;
  margin: 0 0 1.45rem;
  opacity: 1;
}

.main-glass {
  background: rgba(23, 12, 61, 0.3);
  width: 701px;
  height: 428px;
  border-radius: 10px;
  position: absolute;
  top: 200px;
  left: 0px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  box-shadow: rgb(0 0 0 / 25%) 0px 20px 40px,
    rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
}

/* Sales Glass */

@media (min-width: 450px) {
  /* .interface-wrapper:hover > .sales-glass {
    transform: translate(-120px, 60px);
    z-index: 4;
  } */
}

.sales-glass {
  background: rgba(23, 12, 61, 0.2);
  width: 400px;
  height: 273px;
  border-radius: 10px;
  position: absolute;
  top: 370px;
  left: 40px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
}

/* Swap Glass */

@media (min-width: 450px) {
  .dzunxg:hover > .swap-glass {
    transform: translate(30px, 30px);
    z-index: 5;
  }
}

.swap-glass {
  background: rgba(23, 12, 61, 0.2);
  width: 414px;
  height: 273px;
  border-radius: 10px;
  position: absolute;
  top: 400px;
  left: 340px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
}

/* End GLass */

.half-highlight {
  /* background: linear-gradient(
    to top,
    rgba(31, 169, 188, 0.5) 50%,
    transparent 50%
  ); */
  background: linear-gradient(
    to top,
    rgba(0, 206, 230, 0.5) 50%,
    transparent 50%
  );
}

a {
  color: black;
  color: var(--main);
  text-decoration: none !important;
}

a:hover {
  color: #00a9bc;
  color: var(--ol-color);
  text-decoration: none !important;
}

#other:focus {
  border:1px solid grey !important;
}

.grey-button {
  border: 1px solid grey !important;
  background: white;
  color: grey;
  margin:0;
  /* border-radius: 10px; */
  padding: 10px 0px;
  /* margin: 40px 0px 40px 0px; */
  border: none;
  width: 100%;
  font-weight: bolder;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}

.grey-button:hover {
  /* background: var(--ol-highlight) !important; */
  opacity: 0.75 !important;
  transform: translateY(-2px) !important;
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px !important;
  box-shadow: var(--box-shadow) !important;
}


.disabled-button {
  background: grey !important;
  color: white;
  margin:0;
  /* border-radius: 10px; */
  padding: 10px;
  /* margin: 40px 0px 40px 0px; */
  border: none;
  width: 100%;
  font-weight: bolder;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}

.disabled-button:hover {
  /* background: var(--ol-highlight) !important; */
  opacity: 0.75 !important;
  transform: translateY(-2px) !important;
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px !important;
  box-shadow: var(--box-shadow) !important;
}

.download-button {
  background: #00a9bc !important;
  background: var(--ol-color) !important;
  color: white;
  margin:0;
  /* border-radius: 10px; */
  padding: 10px;
  /* margin: 40px 0px 40px 0px; */
  border: none;
  width: 100%;
  font-weight: bolder;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}

.download-button:hover {
  /* background: var(--ol-highlight) !important; */
  opacity: 0.75 !important;
  transform: translateY(-2px) !important;
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px !important;
  box-shadow: var(--box-shadow) !important;
}

.platform-button {
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s !important;
}

.platform-button:hover {
  /* background: var(--ol-highlight) !important; */
  opacity: 0.75;
  transform: translateY(-2px);
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
  box-shadow: var(--box-shadow);
}

.crypto-button {
  border: 2px solid #00a9bc !important;
  border: 2px solid var(--ol-color) !important;
  background-color: transparent;
  color: #00a9bc;
  color: var(--ol-color);
  border-radius: 10px;
  padding: 10px;
  margin: 40px 0px 40px 0px;
  border: none;
  width: 200px;
  font-weight: bolder;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}

.crypto-button:hover {
  /* background: var(--ol-highlight) !important; */
  opacity: 0.75;
  transform: translateY(-2px);
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
  box-shadow: var(--box-shadow);
}

.right-text {
  text-align: right;
}

.left-text {
  text-align: left;
}

.center-text {
  text-align: center;
}

.image-wrapper {
  opacity: 0;
  animation: 0.5s ease 0s 1 normal forwards running translateY;
}

.inactive-button {
  background: rgba(160, 160, 162, 1) !important;
  background: var(--gray-color) !important;
  color: white;
  border-radius: 10px;
  height: 40px;
  border: none;
  width: 200px;
  font-weight: 600;
  cursor: not-allowed !important;
}

/* Navbar Section */

.nav-logo {
  margin-top: 10px;
  height: 30px;
  z-index: 1;
}

.nav-contents {
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-other {
  text-align: right;
}

.navigation-bar {
  position: absolute;
  /* height: 60px; */
  /* background: var(--bg-color); */
  width: 100%;
  z-index: 1;
}

.black-button-wrapper {
  background-color: black;
  background-color: var(--mobile-button);
  height: 40px;
  width: auto;
  border-radius: 10px;
  text-align: center;
  /* width: 100px; */
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}

.black-button-wrapper:hover {
  opacity: 0.75;
  transform: translateY(-2px);
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
  box-shadow: var(--box-shadow);
}

.platform-logo {
  height: 35px;
  width: auto;
  margin: auto;
}

.description-text {
  font-size: 20px;
  font-size: var(--description-text-desktop);
}

.exchange-logo {
  height: 70px;
}

input:focus{
  border:none;
  outline: none;
}

/* Mobile Menu Section */

/* Mobile devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .exchange-logo {
    height: 50px;
  }

  .calculator-box {
    background: rgba(23, 12, 61, 0.3);
    margin: 20px auto !important;
    border-radius: 15px;
    /* position: absolute; */

    left: 0px;
    -webkit-backdrop-filter: blur(10px) !important;
            backdrop-filter: blur(10px) !important;
    box-shadow: rgb(0 0 0 / 25%) 0px 20px 40px,
      rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
  }

  .stats-row {
    margin: 0rem 0.5rem !important;
    text-align: center !important;
  }

  .desktop-menu {
    display: none !important;
  }

  .mobile-centered {
    text-align: center !important;
  }

  .description-text {
    font-size: 12px;
    font-size: var(--description-text-mobile);
  }

  .product-heading {
    font-size: 14px !important;
  }

  .black-button-wrapper {
    width: 125px !important;
    height: 35px !important;
  }

  .platform-logo {
    height: 30px !important;
    padding-top: 1px;
  }

  .media-logo {
    height: 30px !important;
  }

  .button-margin {
    margin: 20px 0px !important;
  }

  .main-tagline {
    font-size: 52px !important;
    font-size: var(--heading-text-tablet) !important;
  }

  .product-title {
    font-size: 32px !important;
    font-size: var(--heading-text-mobile) !important;
    margin-bottom: 20px;
  }

  .newsletter-title {
    font-size: 32px !important;
    font-size: var(--heading-text-mobile) !important;
  }

  .newsletter-description {
    font-size: 12px !important;
    font-size: var(--description-text-mobile) !important;
  }

  .footer-category {
    font-size: 20px !important;
    font-size: var(--description-text-desktop) !important;
  }

  .footer-item {
    font-size: 12px !important;
    font-size: var(--description-text-mobile) !important;
  }

  .footer-logo {
    height: 20px !important;
  }

  .footer-copyright {
    font-size: 12px !important;
    font-size: var(--description-text-mobile) !important;
  }

  .footer-language {
    font-size: 12px !important;
    font-size: var(--description-text-mobile) !important;
  }

  .media-heading {
    font-size: 32px !important;
    font-size: var(--heading-text-mobile) !important;
  }

  .calculator-title {
    font-size: 32px !important;
    font-size: var(--heading-text-mobile) !important;
  }

  .calculator-subheading {
    font-size: 16px !important;
    font-size: var(--description-text-tablet) !important;
  }

  .calculator-asset {
    font-size: 16px !important;
    font-size: var(--description-text-tablet) !important;
  }

  .calc-card-heading {
    font-size: 28px !important;
    font-size: var(--calc-heading-mobile) !important;
  }

  .card-text {
    font-size: 16px !important;
    font-size: var(--description-text-tablet) !important;
  }

  .icon-box {
    height: 45px !important;
    width: 45px !important;
    font-size: 20px !important;
    font-size: var(--description-text-desktop) !important;
  }

  .icon-box-coins {
    height: 45px !important;
    width: 45px !important;
    font-size: 20px !important;
    font-size: var(--description-text-desktop) !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .mobile-menu {
    display: none !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .mobile-menu {
    display: none !important;
  }
  .hero-content {
    margin-top: 80px;
  }

  .product-wrapper {
    margin-top: -80px;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 28px;
  /* right: 36px; */
  /* top: 36px; */
  text-align: right;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: black;
  background: var(--main);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #00a9bc;
  background: var(--ol-color);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: rgba(160, 160, 162, 1);
  background: var(--gray-color);
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  background: var(--bg-color);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
  box-shadow: var(--box-shadow);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  /* fill: #373a47; */
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  /* background: rgba(0, 0, 0, 0.3); */
}

/* Hero Section */

.hero-wrapper {
  /* height: 100vh; */
  /* max-height: 1080px; */
  /* flex-direction: row;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center; */
  padding: 100px 0px 150px 0px;
}

.download-wrapper {
  background: rgba(32, 169, 188, 0.1);
  background: var(--transparent-container);
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}

.download-wrapper:hover {
  opacity: 0.6;
  cursor: pointer;
}

.main-tagline {
  color: black;
  color: var(--main);
  margin: 40px 0px 40px 0px;
  font-size: 72px;
  font-size: var(--heading-text-desktop);
}

.maintainer-link {
  opacity: 0.95 !important;
}

.maintainer-link:hover {
  opacity: 0.75 !important;
}

.download-section {
  margin: 40px 0px 40px 0px;
}

.main-tagline span {
  color: #00cee6;
  color: var(--ol-highlight);
}

.main-description {
  color: black;
  color: var(--main);
  font-size: 20px;
  font-size: var(--description-text-desktop);
}

.device-image {
  height: auto;
  width: 100%;
}

.stats-row {
  background: rgba(32, 169, 188, 0.1);
  background: var(--transparent-container);
  border-radius: 10px;
  padding: 40px;
  margin-top: 100px !important;
}

.stat-value {
  color: #00a9bc;
  color: var(--ol-color);
  font-weight: 600;
  font-size: 52px;
  text-align: center;
}

.stat-description {
  color: black;
  color: var(--main);
  font-size: 20px;
  font-size: var(--description-text-desktop);
  text-align: center;
  font-weight: 400;
}

@media only screen and (max-width: 992px) {
  .main-tagline {
    text-align: center;
  }

  .main-description {
    text-align: center;
  }

  .hero-content .button-wrapper {
    margin: 10px auto;
    text-align: center;
  }

  .maintainer-link {
    display: none;
  }
}

/* Press Section */

.media-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.media-col {
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}

.media-logo {
  height: 40px;
  text-align: center;
  margin: auto;
  /* width: 100%; */
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  opacity: 0.5;
}

.media-logo:hover {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  opacity: 1;
  cursor: pointer;
}

.media-heading {
  font-size: 52px;
  text-align: center;
  font-weight: 300;
  margin-bottom: 60px;
  color: black;
  color: var(--main);
}

.media-heading span {
  font-weight: 600;
}

/* Products Section */

.product-section {
  padding: 150px 0px 0px 0px;
}

.product-title {
  font-size: 52px;
  font-weight: 300;
  color: black;
  color: var(--main);
}

.product-title span {
  font-weight: 600;
}

.product-heading {
  font-size: 24px;
  color: rgba(160, 160, 162, 1);
  color: var(--gray-color);
  cursor: pointer;
}

.product-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* margin: 40px 0px 40px 0px; */
  padding: 0px;
  margin: 40px 0px;
}

.button-margin {
  margin: 40px 0px;
}

.swap-image {
  /* border-radius: 15px !important;
  box-shadow: var(--box-shadow); */
}

.navigation-link {
  display: flex !important;
  margin-top: 10px;
  color: rgba(160, 160, 162, 1);
  color: var(--gray-color);
  font-weight: 400;
  display: flex;
  background: none;
  border: none;
  border-radius: 14px;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  background-blend-mode: overlay;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  z-index: 5;
}

.navigation-active {
  color: black;
  color: var(--main);
  font-weight: 600;
}

.navigation-link:hover {
  color: #00a9bc;
  color: var(--ol-color);
  background: #f2f2f2;
  background: var(--main-transparent);
  /* background: rgba(255, 255, 255, 0.2); */
  /* box-shadow: rgb(31 47 71 / 25%) 0px 20px 40px, rgb(0 0 0 / 10%) 0px 1px 5px,
    rgb(255 255 255 / 40%) 0px 0px 0px 0; */
  transform: translateY(-2px);
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
  box-shadow: var(--box-shadow);
}

.navigation-link-mobile {
  display: flex !important;
  margin-top: 10px;
  color: black;
  color: var(--main);
  font-weight: 400;
  display: flex;
  background: none;
  border-bottom: none;
  border-radius: 14px;
  padding: 10px 10px;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  background-blend-mode: overlay;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}

.navigation-active-mobile {
  color: black;
  color: var(--main);
  font-weight: 600;
}

.navigation-link-mobile:hover {
  color: #00a9bc;
  color: var(--ol-color);
  background: #f2f2f2;
  background: var(--main-transparent);
  /* background: rgba(255, 255, 255, 0.2); */
  /* box-shadow: rgb(31 47 71 / 25%) 0px 20px 40px, rgb(0 0 0 / 10%) 0px 1px 5px,
    rgb(255 255 255 / 40%) 0px 0px 0px 0; */
  transform: translateY(-2px);
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
  box-shadow: var(--box-shadow);
}

.modal-content {
  /* position: absolute; */
  margin: auto !important;
  /* max-width: 260px; */
  /* margin-top: 10px; */
  padding: 20px;
  /* right: 0.5rem; */
  background-color: rgba(255, 255, 255, 0.75) !important;
  background-color: var(--dropdown-bg) !important;
  background: none !important;
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px !important;
  box-shadow: var(--box-shadow) !important;
  -webkit-backdrop-filter: blur(8px) brightness(80%) saturate(100%) !important;
          backdrop-filter: blur(8px) brightness(80%) saturate(100%) !important;
  border-radius: 20px !important;
  visibility: visible !important;
  opacity: 1 !important;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s !important;
  transform-origin: center top !important;
  transform: rotateX(0deg) translateY(0px) !important;
  z-index: 1 !important;
  border: none !important;
}

.mobile-dropdown {
  position: absolute;
  max-width: 260px;
  margin-top: 10px;
  padding: 20px;
  right: 0.5rem;
  background: rgba(255, 255, 255, 0.75);
  background: var(--dropdown-bg);
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
  box-shadow: var(--box-shadow);
  -webkit-backdrop-filter: blur(8px) brightness(80%) saturate(100%);
          backdrop-filter: blur(8px) brightness(80%) saturate(100%);
  border-radius: 20px;
  visibility: visible;
  opacity: 1;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform-origin: center top;
  transform: rotateX(0deg) translateY(0px);
  z-index: 1;
}

.mobile-hidden {
  position: absolute;
  max-width: 260px;
  margin-top: 10px;
  padding: 20px;
  right: 0px;
  background: rgba(15, 14, 71, 0.3);
  box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
  -webkit-backdrop-filter: blur(40px) brightness(80%) saturate(150%);
          backdrop-filter: blur(40px) brightness(80%) saturate(150%);
  border-radius: 20px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform-origin: center top;
  transform: rotateX(-20deg) translateY(-5px);
}

.exchange-modal {
  /* position: absolute; */
  /* max-width: 260px; */
  margin-top: 10px;
  padding: 20px;
  right: 0.5rem;
  background: rgba(255, 255, 255, 0.75);
  background: var(--dropdown-bg);
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
  box-shadow: var(--box-shadow);
  -webkit-backdrop-filter: blur(8px) brightness(80%) saturate(100%);
          backdrop-filter: blur(8px) brightness(80%) saturate(100%);
  border-radius: 20px;
  visibility: visible;
  opacity: 1;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform-origin: center top;
  transform: rotateX(0deg) translateY(0px);
  z-index: 1 !important;
}

.exchange-hidden {
  position: absolute;
  /* max-width: 260px; */
  margin-top: 10px;
  padding: 20px;
  right: 0px;
  background: rgba(15, 14, 71, 0.3);
  box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
  -webkit-backdrop-filter: blur(40px) brightness(80%) saturate(150%);
          backdrop-filter: blur(40px) brightness(80%) saturate(150%);
  border-radius: 20px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform-origin: center top;
  transform: rotateX(-20deg) translateY(-5px);
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .mobile-hidden {
    transform: skewY(-5deg) rotate(5deg) translateY(-30px);
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .mobile-dropdown {
    transform: skewY(0deg) rotate(0deg) translateY(0px);
  }
}

.navigation-dropdown {
  position: absolute;
  max-width: 260px;
  margin-top: 10px;
  padding: 20px;
  background: rgba(15, 14, 71, 0.3);
  box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
  -webkit-backdrop-filter: blur(40px) brightness(80%) saturate(150%);
          backdrop-filter: blur(40px) brightness(80%) saturate(150%);
  border-radius: 20px;
  visibility: visible;
  opacity: 1;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform-origin: center top;
  transform: rotateX(0deg) translateY(0px);
}

.product-tab {
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}

.active-product {
  color: #00a9bc !important;
  color: var(--ol-color) !important;
}

.product-description {
  color: black;
  color: var(--main);
  /* font-size: var(--description-text-desktop); */
  font-weight: 400;
}

.product-image {
  width: 100%;
  height: auto;
}

/* EMB Calculator Section */

.calculator-section {
  padding: 50px 0px 150px 0px;
}

.exchange-text {
  color: black !important;
  color: var(--main) !important;
}

.exchange-col:hover {
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px,
    rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
  box-shadow: var(--box-shadow);
  border-radius: 15px;
}

.exchange-modal a {
  font-size: 20px;
  font-size: var(--description-text-desktop);
  color: black;
  color: var(--main);
  height: 100%;
  width: 100%;
}

.exchange-modal a:hover {
  color: black;
  color: var(--main);
}

.calculator-title {
  font-size: 52px;
  font-weight: 300;
  color: black;
  color: var(--main);
  text-align: center;
}

.calculator-title span {
  font-weight: 600;
}

.calculator-asset {
  font-size: 24px;
  font-weight: 600;
  color: #00a9bc;
  color: var(--ol-color);
  text-decoration: none;
  text-align: center;
}

.calculator-subheading {
  font-size: 20px;
  font-size: var(--description-text-desktop);
  font-weight: 400;
  color: black;
  color: var(--main);
  text-align: center;
  margin: 20px 0px 40px 0px;
}

.calculator-box {
  margin: auto;
  width: 75%;
  min-width: 300px;
  max-width: 500px;
  padding: 60px 40px 60px 40px;
  border-radius: 15px;
  background: rgba(23, 12, 61, 0.1);
  margin: auto !important;
  border-radius: 15px;
  /* position: absolute; */
  left: 0px;
  -webkit-backdrop-filter: blur(10px) !important;
          backdrop-filter: blur(10px) !important;
  box-shadow: rgb(0 0 0 / 25%) 0px 20px 40px,
    rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
}

/* #tooltip-bottom {
  padding: 40px;
} */

.tooltip-inner {
  width: 600px !important;
  max-width: 600px;
  padding: 40px !important;
  color: black;
  text-align: center;
  background-color: #fff;
  border-radius: 15px;
}

.tooltip-text {
  color: black;
  font-size: 14px;
}

.information-tooltip {
  margin: auto;
  text-align: center;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.information-text {
  text-align: center;
  padding: 100px 0px 50px 0px;
  font-size: 28px;
  color: black;
  color: var(--main);
}

.calc-row {
  padding: 15px 0px;
  justify-content: center;
  align-items: center;
}

.calc-card-heading {
  font-size: 36px;
  color: black;
  color: var(--main);
  font-weight: bolder;
}
.calc-card-subheading {
  font-size: 16px;
  color: rgba(160, 160, 162, 1);
  color: var(--gray-color);
  font-weight: 400;
}

.slider-wrapper {
  margin: 60px 0px 20px 0px;
}

.card-text {
  color: black;
  color: var(--main);
  font-weight: 400;
  font-size: 28px;
  margin: 10px 0px 10px 0px;
}

.icon-box {
  padding: 10px 10px 10px 10px;
  text-align: center;
  width: 60px;
  height: 60px;
  color: #00a9bc;
  color: var(--ol-color);
  font-size: 28px;
  background: rgba(23, 12, 61, 0.1);
  border-radius: 15px;
  /* position: absolute; */
  left: 0px;
  -webkit-backdrop-filter: blur(10px) !important;
          backdrop-filter: blur(10px) !important;
  box-shadow: rgb(0 0 0 / 25%) 0px 20px 40px,
    rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
}

.icon-box-coins {
  padding: 7.5px 10px 10px 10px;
  text-align: center;
  width: 60px;
  height: 60px;
  color: #00a9bc;
  color: var(--ol-color);
  font-size: 28px;
  background: rgba(23, 12, 61, 0.1);
  border-radius: 15px;
  /* position: absolute; */
  left: 0px;
  -webkit-backdrop-filter: blur(10px) !important;
          backdrop-filter: blur(10px) !important;
  box-shadow: rgb(0 0 0 / 25%) 0px 20px 40px,
    rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
}

/* Newsletter Section */

.newsletter-section {
  padding: 0px 0px 0px 0px;
  /* background-color: var(--ol-color); */
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .newsletter-container {
    box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
  }
}

.newsletter-container {
  background: rgba(23, 12, 61, 0.15);
  padding: 80px 60px 80px 60px;
  border-radius: 15px;
  /* position: absolute; */
  margin-top: 100px;
  left: 0px;
  -webkit-backdrop-filter: blur(10px) !important;
          backdrop-filter: blur(10px) !important;
  box-shadow: rgb(0 0 0 / 25%) 0px 20px 40px,
    rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
}

.newsletter-title {
  font-size: 48px;
  font-weight: 400;
  color: black;
  color: var(--main);
}

.newsletter-submit {
  background-color: #00a9bc;
  background-color: var(--ol-color);
  border-radius: 0px 10px 10px 0px;
  color: white;
  font-weight: bold;
  text-align: center;
  height: 40px;
  border: none;
  width: 100%;
}

.newsletter-submit:hover {
  opacity: 0.75;
}

.newsletter-description {
  color: black;
  color: var(--main);
  font-size: 18px;
  font-weight: 300;
  margin: 15px 0px 25px 0px;
}

.newsletter-input {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 10px 0px 0px 10px;
  padding-left: 10px;
}

input:focus-visible {
  border: none !important;
  outline: none;
}

/* Footer Section */

.footer-category {
  font-size: 32px;
  font-weight: 600;
  color: black;
  color: var(--main);
}

.footer-logo {
  height: 30px;
}

.social-icon {
  height: 30px !important;
  margin: 20px 10px !important;
}

.social-icon:hover {
  opacity: 0.75;
}

.footer-social-row {
  text-align: right;
  flex-direction: row;
}
.footer-item {
  color: black;
  color: var(--main);
  font-size: 18px;
}

a.footer-item:hover {
  color: #00a9bc;
  color: var(--ol-color);
}

.footer-social-row {
  margin-top: 20px !important;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(160, 160, 162, 0.25);
  border-bottom: 1px solid var(--gray-light);
}

.footer-copyright {
  color: black;
  color: var(--main);
  margin-bottom: 20px;
}

.footer-language {
  text-align: right;
  color: black;
  color: var(--main);
  font-weight: bold;
  margin-bottom: 150px;
}

/* Liquid Button */

/* Range Slider */

.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
  background: #00a9bc !important;
  background: var(--ol-color) !important;
  background-color: #00a9bc !important;
  background-color: var(--ol-color) !important;
  opacity: 1 !important;
  font-weight: 600;
  font-size: 18px !important;
}

.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__caret {
  /* background-color: var(--ol-color); */
  color: #00a9bc !important;
  color: var(--ol-color) !important;
}

.range-slider__wrap
  .range-slider__tooltip.range-slider__tooltip--top
  .range-slider__tooltip__caret::before {
  border-top-color: #00a9bc !important;
  border-top-color: var(--ol-color) !important;
}

/* Gradient Section */
.main-bg-gradient {
  height: 890px;
  background: linear-gradient(
    189.16deg,
    rgb(18, 27, 45) 13.57%,
    rgb(20, 54, 100) 98.38%
  );
  position: relative;
  overflow: hidden;
}

.signup-bg-gradient {
  height: 890px;
  background: linear-gradient(
    189.16deg,
    rgb(18, 27, 45) 30%,
    rgb(20, 54, 100) 70%
  );
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.top-wave {
  position: absolute;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  top: 137px;
  height: 900px;
  background-image: url(/static/media/hero-wave1.4bf92db6.svg);
}

.hero-blur {
  position: absolute;
  width: 100%;
  height: 800px;
  -webkit-backdrop-filter: blur(60px);
          backdrop-filter: blur(60px);
  /* z-index: -1; */
}

.lower-wave {
  position: absolute;
  width: 110%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  top: 380px;
  left: 0px;
  height: 600px;
  background-image: url(/static/media/hero-wave2.69f47388.svg);
}

.bottom-wave {
  background: linear-gradient(
    rgba(31, 31, 71, 0.25) -18.72%,
    rgb(18, 27, 45) 37.6%
  );
}

@media (min-width: 1440px) {
  .bottom-wave {
    transform: scale(1.5);
  }
}

.bottom-wave {
  position: absolute;
  width: 100%;
  top: 520px;
  height: 500px;
  -webkit-clip-path: url(#clip);
          clip-path: url(#clip);
  transform-origin: left top;
  background: linear-gradient(
    rgba(190, 255, 255, 0.25) -18.72%,
    rgb(16, 27, 45) 37.6%
  );
}

/* Product Gradient */

.calc-wave-wrapper {
  position: absolute;
  height: 1500px;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  animation: 1s ease 0s 1 normal forwards running;
}

.calc-wave-1 {
  position: absolute;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
  top: 0px;
  background-image: url(/static/media/product-wave1.fa5271fd.svg);
  height: 800px;
}

.calc-wave-2 {
  position: absolute;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
  top: 250px;
  background-image: url(/static/media/product-wave3.26534d18.svg);
  height: 600px;
}

.calc-wave-3 {
  position: absolute;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
  top: 380px;
  background-image: url(/static/media/product-wave3.26534d18.svg);
  height: 600px;
}

.calc-wave-blur {
  -webkit-filter: blur(30px);
          filter: blur(30px);
}

.calc-wave-blur2 {
  position: absolute;
  top: 350px;
  left: -50px;
  width: 400px;
  height: 150px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
}

.calc-wave-blur3 {
  position: absolute;
  width: 400px;
  height: 150px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  top: 560px;
  left: auto;
  right: -50px;
}

@media (min-width: 1440px) {
  .calc-wave-4 {
    top: 160px;
  }
}

.calc-wave-4 {
  position: absolute;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
  top: 200px;
  background-image: url(/static/media/product-wave4.66e73131.svg);
  height: 1000px;
}

.calc-wave-5 {
  position: absolute;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
  top: 450px;
  background-image: url(/static/media/product-wave5.9557e7b8.svg);
  height: 1000px;
}

.calc-dynamic-wave {
  background: linear-gradient(
    rgba(31, 31, 71, 0.25) -18.72%,
    rgb(31, 31, 71) 37.6%
  );
}

@media (min-width: 1440px) {
  .calc-dynamic-wave {
    transform: scale(1.5);
    left: -1px;
  }
}

.calc-dynamic-wave {
  position: absolute;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  transform-origin: left top;
  top: 800px;
  height: 500px;
  -webkit-clip-path: url(#wave6);
          clip-path: url(#wave6);
  background: linear-gradient(
    rgba(242, 246, 255, 0.5) 0%,
    rgb(242, 246, 255) 40%
  );
}

/* Calculator Wave */

.calc-wave1 {
  position: absolute;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  top: 137px;
  height: 900px;
  background-image: url(/static/media/calc-wave1.3f10f184.svg);
  z-index: -1;
}

.calc-blur {
  position: absolute;
  width: 100%;
  height: 800px;
  -webkit-backdrop-filter: blur(60px);
          backdrop-filter: blur(60px);
  /* z-index: -1; */
  transform: scaleX(-1);
}

.calc-wave2 {
  position: absolute;
  width: 110%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 110%;
  top: 380px;
  left: 0px;
  height: 600px;
  background-image: url(/static/media/calc-wave2.dd5a53f5.svg);
  z-index: 0;
}

.calc-wave3 {
  background: linear-gradient(
    rgba(31, 31, 71, 0.25) -18.72%,
    rgb(18, 27, 45) 37.6%
  );
}

@media (min-width: 1440px) {
  .calc-wave3 {
    transform: scale(1.5);
  }
}

.calc-wave3 {
  position: absolute;
  width: 100%;
  top: 520px;
  height: 500px;
  -webkit-clip-path: url(#clip);
          clip-path: url(#clip);
  transform-origin: left top;
  background: linear-gradient(
    rgba(190, 255, 255, 0.25) -18.72%,
    rgb(16, 27, 45) 37.6%
  );
  z-index: 0;
  /* transform: scaleX(-1); */
}

